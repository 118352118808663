<template>
  <div class="page-content w-100">
    <div class="p-4">
      <div class="row">
        <!-- <div class="row d-none d-lg-block">
          <div class="col-md-12 col-12">
            <div class="border-color p-4" style="border-radius: 20px; background-color: #ffff;">
              <router-link :to="'/' + role + '/newpost'" class="nav-link text-color">Publish my wisdom</router-link>
            </div>
          </div>
        </div> -->
        <div class="d-block d-lg-none">
          <div class="btn-floating-add">
            <router-link :to="'/' + role + '/newpost'" class="nav-link text-color">
              <font-awesome-icon :icon='["fas", "plus-circle"]' />
            </router-link>
          </div>
        </div>


        <div class="box-shadow p-3 mt-4">
          <div class="row mt-3" >
            <div class="col-md-12 col-12">
              <h4 class="text-20 font-weight-bold">Welcome to RxIx</h4>
            </div>
            <div class="col-12 mt-3">
              It is our pleasure to welcome you to RxIx. 
Our initiative is to support doctors in their patient care efforts. 

            </div>
            <div class="col-12 mt-3">
              Help us by selecting the specialties that are of interest to you.
            </div>
            <div class="col-12 mt-3">
              Based on your choice, we
              <ul>
                <li>
                  List topics aligned to the specialties as your topics of interest
                </li>
                <li>
                  Create your library of relevant resources
                </li>
                <li>
                  Enroll you into the related patient support groups
                </li>
              </ul>
            </div>
            <div class="row mt-3">
              <div class="col-4 text-btn">
                <button class="btn btn-blue-color text-white">
                  Ok, let me select
                </button>
              </div>
            </div>
            <div class="col-12 mt-3">
              Do take a quick tour of the app and get acquainted with the features and services we
              currently offer.
            </div>

            <div class="row mt-3">
              <p class="text-start font-weight-bold">Best Wishes!</p>
              <div class="col-md-6 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
              </div>
              <div class="col-md-12 col-6" style="margin-top:-40px" >
              <p class="text-end" style="float:right"> {{ adminPayload.createdAt }}
              </p>
            </div>
              
            </div>
          </div>
          <nav class="nav nav-fill feed-icon">
            <router-link to="" title="Send private" class="nav-link feed-icon-link">
              <font-awesome-icon :icon="['fas', 'paper-plane']" />
              <p class="feed-title d-block">Reply privately</p>
            </router-link>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
export default {
  props: ["role"],
  data() {
    return {
   adminPayload:{}
    }
  },
  created: function () {
    this.getAdmin();
   
  },
  methods: {
    getAdmin(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let adminID = localStorage.getItem("AdminsignIn");
          this.adminIdObj = JSON.parse(adminID);
      axios
      .get(`${BASE_API_URL}/admin/${this.adminIdObj}/one`)
      .then((response)=>{
       this.adminPayload=response.data
       this.adminPayload.createdAt =moment(this.adminPayload.createdAt).format("DD/MM/YYYY, h:mm a");
      }

      )
    },

 
  },
}
</script>
<style>
.box-shadow.p-3.mt-4 {
    background-color: #fff;
    border-radius: 20px;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

.btn-text {
    text-align: center;
    padding: 25px;
    color: #00979e;
}

.col-12.div-align {
    text-align: -webkit-center;
    width: 65%;
    margin: auto;
}

button.btn.btn-blue-color.text-white {
    width: 100%;
}

.eyes-text1 p {
    font-size: 18px;
    font-weight: bold;
}

.box-text-bg1 {
    font-size: 45px;
    color: #fff;
}

/* .row.box-items-space1 {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 22% 22% 22%;
    justify-content: center;
} */

@media screen and (max-width:992px) {
    .row.box-items {
        margin-top: 60px;
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        margin-left: 40px;
    }
}

.col-opt-box1 {
    width: 100%;
    display: grid;
    justify-content: center;
}

.col-fix.mt-3.color-bg1 {
    border: 2px solid #00979e;
    width: 206px;
    /* margin-bottom: 30px; */
    /* background-color: #00979e; */
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text-bg1 {
    text-align: center;
    padding: 20px;
    color: #fff;
    background-color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text-bg1 {
        text-align: center;
        margin: 20px;
        padding: 20px;
        color: #fff;
        background-color: #00979e;
    }
}

.btn.btn-action-background1 {
    color: #00979e;
}

.box-shadow1 {
    box-shadow: 0px 0px 0px 1px red, 0px 0px 0px 0px red !important;
}

.box-shadow2 {
    box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
}
</style>

