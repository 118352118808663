<template>
<div class="">
    <adminIndex role="admin" />
</div>
</template>


<script>
import adminIndex from '../common/adminindexlayout.vue'
export default {

  name: 'app',
  components: {
    adminIndex,
  },


}
</script>